.block {
  user-select: none;
  min-width: 45px;
  padding: 1em;
  box-sizing: border-box;
  min-height: 90px;
  cursor: all-scroll;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 10px 2.5% 5px;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 15%);
  transition: all 0.2s ease 0s;
  transition-property: color;
}
.block:hover {
  color: #d278c9;
}

.customStyleManager > .ant-collapse > .ant-collapse-item > .ant-collapse-header,
.customStyleManager .ant-select-arrow {
  color: #ddd;
}
.customStyleManager > .ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid black;
}
.customStyleManager .ant-collapse-content {
  color: #fff;
  background-color: #44444400;
  border-top: 1px solid black;
}
.customStyleManager > .ant-collapse {
  border: none;
}
.blocks input,
.customStyleManager input,
.customStyleManager .ant-select-selector {
  background-color: #44444400 !important;
  color: #fff;
}
.blocks input:hover,
.blocks input:focus,
.customStyleManager .ant-input:hover,
.customStyleManager .ant-select-selector:hover,
.customStyleManager .ant-input:focus {
  border-color: #d278c9 !important;
}
.customStyleManager .ant-select-focused .ant-select-selector,
.customStyleManager .ant-select-selector:focus,
.customStyleManager .ant-select-selector:active,
.customStyleManager .ant-select-open .ant-select-selector {
  border-color: #d278c9 !important;
  box-shadow: none !important;
}
.ant-select-item-option-content{
    /* color: red !important; */
    font-size: .9rem !important;
    overflow:visible !important;
    white-space:normal !important;
    text-overflow:clip !important;
}